<template>
    <div>
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item label="发布日期">
                <el-date-picker v-model="dataForm.createTimeArr" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                    align="right">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="用户语种">
                <el-select v-model="dataForm.lang" clearable placeholder="--请选择--">
                    <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发布状态">
                <el-select v-model="dataForm.status" clearable placeholder="--请选择--">
                    <el-option v-for="item in statusOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="覆盖IP">
                <el-select v-model="dataForm.virIp" clearable placeholder="--请选择--" @change="selectIpChange">
                    <el-option v-for="item in virIPOpt" :key="item.ip" :label="item.ipName" :value="item.ip">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="覆盖角色">
                <el-select v-model="dataForm.virUid" clearable placeholder="--请选择--">
                    <el-option v-for="item in virUserOpt" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="记忆类型">
                <el-select v-model="dataForm.memoryType" clearable placeholder="--请选择--">
                    <el-option v-for="item in memoryTypeOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button @click="getDataList()" type="primary">搜索</el-button>
                <el-button @click="refresh()">重置</el-button>
                <el-button @click="addOrUpdateHandle(0)" type="danger">新增</el-button>
                <!-- <el-button @click="exportList()"  style="background-color: #00BFBF; color: #fff;">导出</el-button> -->
            </el-form-item>
        </el-form>

        <el-table :data="dataList" border v-loading="dataListLoading" style="width: 100%; margin-top: 2vh;">

            <el-table-column prop="id" width="80" header-align="center" align="center" label="任务ID">
            </el-table-column>

            <el-table-column prop="memoryType" header-align="center" align="center" label="记忆类型" width="100">
                <template slot-scope="scope">
                    <div>
                        <span v-if="scope.row.memoryType === 1">命名记忆</span>
                        <span v-else-if="scope.row.memoryType === 2">历史记忆</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="lang" header-align="center" align="center" label="配置语种">
            </el-table-column>

            <el-table-column prop="status" header-align="center" align="center" label="任务状态" width="120">
                <template slot-scope="scope">
                    <div>
                        <el-tag v-if="scope.row.status === 0" type="success" size="small">进行中</el-tag>
                        <el-tag v-else-if="scope.row.status === 1" type="danger" size="small">已终止</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="ipList" header-align="center" width="200px" align="center" label="覆盖IP">
                <template slot-scope="scope">
                    {{ getVirIpLabel(virIPOpt, scope.row.ipList, 'ip', 'ipName') }}
                </template>
            </el-table-column>
            <el-table-column prop="virUidList" header-align="center" align="center" label="覆盖角色" width="200px">
                <template slot-scope="scope">
                    {{ getVirUserLabel(scope.row.ipList, scope.row.virUidList) }}
                </template>
            </el-table-column>
            <!-- 
            <el-table-column prop="content" width="150px" header-align="center" align="center" label="内容">
            </el-table-column> -->

            <el-table-column prop="ctime" width="160" header-align="center" align="center" label="任务创建时间">
            </el-table-column>
            <el-table-column prop="opName" header-align="center" align="center" label="配置人">
            </el-table-column>

            <el-table-column fixed="right" header-align="center" align="center" width="100" label="操作">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.status === 0" type="text" size="small" style="color: rgba(0, 191, 191, 1)"
                        @click="handleStopPush(scope.row.id)">终止任务
                    </el-button>
                    <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row)"
                        style="color: rgba(236, 128, 141, 1)">查看详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
            :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    </div>
</template>
<script>
import AddOrUpdate from "./memory-add-or-update";
import { pickerOptions } from "@/views/common/commonOpt";

const sourceDataForm = {
    id: '',
    lang: '',
    content: '',
    virIp: '',
    virUid: '',
    memoryType: '',
    startTime: '',
    endTime: '',
    status: '',
    createTimeArr: [],
}
export default {
    data() {
        return {
            dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            langOpt: [

                {
                    value: 'en',
                    label: 'EN'
                },

            ],
            statusOpt: [
                {
                    value: 0,
                    label: '进行中'
                },
                {
                    value: 1,
                    label: '已终止'
                },
            ],
            memoryTypeOpt: [
                {
                    value: 1,
                    label: '命名记忆'
                },
                {
                    value: 2,
                    label: '历史记忆'
                },
            ],
            virIPOpt: [],
            virUserOpt: [],
            virMapOpt: [],
            pickerOptions: pickerOptions,
            addOrUpdateVisible: true
        }
    },
    components: {
        AddOrUpdate
    },
    activated() {
        this.getDataList()
        this.getIpList()
    },
    methods: {
        refresh() {
            this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
            this.pageIndex = 1
            this.pageSize = 10
            this.getDataList()
        },
        getIpList() {
            this.$http({
                url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=0`),
                method: 'post',
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.virIPOpt = JSON.parse(JSON.stringify(data.result))
                    this.virMapOpt = JSON.parse(JSON.stringify(data.result))
                    this.virIPOpt.map(e => { delete e.virUserResDtoList }) //然后删除属性virUserResDtoList
                    this.virIPOpt = this.virIPOpt.filter(item => !["", null, undefined, NaN].includes(item.ip))

                } else {
                    this.$message.error(data.msg)
                }
            })
        },
        selectIpChange(val) {
            this.virUserOpt = []
            if (val) {
                this.$http({
                    url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=${val}`),
                    method: 'post',
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        if (data.result.length !== 0) {
                            this.virUserOpt = data.result[0].virUserResDtoList
                        }
                        let map = new Map();
                        for (let item of this.virUserOpt) {
                            if (!map.has(item.id)) {
                                map.set(item.id, item)
                            }
                        }
                        this.virUserOpt = [...map.values()]

                        this.virUserOpt = this.virUserOpt.filter(function (item, index) {
                            return item['name'] != 'ALL'
                        })
                        this.virUserOpt.push({
                            id: '',
                            name: 'ALL'
                        })
                    }
                })
            } else {
                this.dataForm.virUid = ''
            }

        },
        getVirIpLabel(list, ipstr, value, label) {
            let temp = []
            let ipList = ipstr.replace(/\[|]/g, '').replace(/["']/g, '').split(',')
            ipList.forEach(ip => {
                if (ip != '' && Array.isArray(list) && list.length != 0) {
                    let str = !list.find(item => item[value] == ip) ? ip : list.find(item => item[value] == ip)[label]
                    temp.push(str)
                } else {
                    temp.push(ip)
                }
            })
            return temp.join('\n\r').replace(/\[|]/g, '')
        },

        getVirUserLabel(ipStr, virUserStr) {
            let virUserOpt = []
            let virUser = []
            let ipList = ipStr.replace(/\[|]/g, '').replace(/["']/g, '').split(',')
            let virUserList = virUserStr.replace(/\[|]/g, '').replace(/["']/g, '').split(',')

            ipList.forEach(ip => {
                if (ip != '') {
                    virUserOpt = !this.virMapOpt.find(item => item['ip'] == ip) ? virUserOpt.concat([]) : virUserOpt.concat(this.virMapOpt.find(item => item['ip'] == ip).virUserResDtoList)
                }
            })

            virUserList.forEach(id => {
                if (id != '') {
                    let str = !virUserOpt.find(item => item['id'] == id) ? id : virUserOpt.find(item => item['id'] == id)['name']
                    virUser.push(str)
                }
            })
            return virUser.join('\n\r').replace(/\[|]/g, '')

        },

        // 获取数据列表
        getDataList() {
            this.dataListLoading = true

            this.$http({
                url: this.$http.adornUrl('/admin/chatMemoryTask/list'),
                method: 'post',
                data: this.$http.adornData({
                    ...this.dataForm,
                    startTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[0] : null,
                    endTime: (this.dataForm.createTimeArr && this.dataForm.createTimeArr.length > 0) ? this.dataForm.createTimeArr[1] : null,
                    page: this.pageIndex,
                    limit: this.pageSize,
                })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.dataList = data.result.data
                    this.totalPage = data.result.last_page
                } else {
                    this.$message.error(data.msg)
                }
                this.dataListLoading = false
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList()
        },
        // 新增 / 修改
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id)
            })
        },
        handleStopPush(id) {
            this.$confirm('点击后任务下配置记忆功能将失效，请确认是否终止此配置任务', '提示', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {
                this.$http({
                    url: this.$http.adornUrl(`/admin/chatMemoryTask/pause?taskId=${id}`),
                    method: 'post',
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        this.getDataList()
                        this.$message({
                            message: '终止任务成功',
                            type: 'success',
                            duration: 1000,
                            onClose: () => {
                                this.visible = false
                            }
                        })
                    } else {
                        this.$message.error(data.msg)
                    }
                })
            })
        },
    },
}
</script>
<style scoped lang="scss">
::v-deep(.el-table .cell) {
    white-space: pre-line;
}

.el-table {
    .el-button+.el-button {
        margin-left: 0;
    }
}
</style>

