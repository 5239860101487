<template>
    <el-dialog width="70%" :title="!dataForm.id ? '对话指令配置' : '查看'" :close-on-click-modal="false" :visible.sync="visible">
        <el-form :model="dataForm" ref="dataForm" @keyup.enter.native="beforeSubmit()" label-width="150px"
            :rules="dataRule">
            <h3>一、 生效场景</h3>
            <el-form-item label="1. 指令生效场景：" prop="memoryType">
                <el-radio-group v-model="dataForm.memoryType" :disabled="dataForm.id !== 0">
                    <el-radio :label="1">命名记忆</el-radio>
                    <el-radio :label="2">历史回忆</el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item label="2. 指令语种：" prop="lang">
                <el-select v-model="dataForm.lang" clearable placeholder="请选择" :disabled="dataForm.id !== 0">
                    <el-option v-for="item in langOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="3. 指令内容：" prop="content">
                <el-input type="textarea" placeholder="请输入内容" v-model="dataForm.content" :rows="5" style="width: 45vw;"
                    :readonly="dataForm.id !== 0"></el-input>
            </el-form-item>

            <h3>二、生效人物</h3>

            <div class="row">
                <el-form-item label="1. 生效IP：" prop="ipList">
                    <el-select v-model="dataForm.ipList" clearable multiple placeholder="请选择" size="small"
                        @change="selectIpListChange" :disabled="dataForm.id !== 0">
                        <el-option v-for="item in virIPOpt" :key="item.ip" :label="item.ipName" :value="item.ip">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="2. 生效角色：" prop="virUidList">
                    <el-select v-model="dataForm.virUidList" clearable multiple placeholder="请选择" size="small"
                        :disabled="dataForm.id !== 0" @change="selectUserChange">
                        <el-option-group v-for="group in virUserOpt" :key="group.ip" :label="group.ipName">
                            <el-option v-for="item in group.virUserResDtoList" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-option-group>
                    </el-select>
                </el-form-item>
            </div>

        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visible = false">取消</el-button>
            <el-button type="primary" @click="beforeSubmit()">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>

const sourceDataForm = {
    id: 0,
    content: '',
    ipList: [],
    virUidList: [],
    memoryType: 1,
    lang: '',
}
export default {
    data() {
        return {
            visible: false,
            dataForm: JSON.parse(JSON.stringify(sourceDataForm)),
            virUserOpt: [],
            virIPOpt: [],
            previousIpOptValue: [],

            dataRule: {
                'content': [
                    { required: true, message: '指令内容不能为空', trigger: 'blur' }
                ],
                'ipList': [
                    { required: true, message: '生效IP不能为空', trigger: 'blur' }
                ],
                'virUidList': [
                    { required: true, message: '生效角色不能为空', trigger: 'blur' }
                ],
                'lang': [
                    { required: true, message: '生效语种不能为空', trigger: 'blur' }
                ],
                'memoryType': [
                    { required: true, message: '记忆类型不能为空', trigger: 'blur' }
                ]
            },
            langOpt: [
                {
                    value: 'en',
                    label: 'EN'
                },

            ],

        }
    },
    mounted() {
        this.getIpList()
    },
    methods: {
        getIpList() {
            this.$http({
                url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=0`),
                method: 'post',
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.virIPOpt = JSON.parse(JSON.stringify(data.result))
                    this.virIPOpt = this.virIPOpt.filter(item => !["", null, undefined, NaN].includes(item.ip))
                } else {
                    this.$message.error(data.msg)
                }
            })
        },
        init(row) {
            console.log('row', row)
            this.dataForm = JSON.parse(JSON.stringify(sourceDataForm))
            this.dataForm.id = !row ? 0 : row.id
            if (this.dataForm.id === 0) {
                // 新增模式
                this.visible = true
                this.virUserOpt = []

                return;
            }
            if (this.$refs['dataForm']) this.$refs['dataForm'].resetFields()


            this.visible = true
            const { lang, ipList, content, memoryType, virUidList } = row
            this.dataForm.lang = lang
            this.dataForm.content = content
            this.dataForm.memoryType = memoryType
            this.dataForm.ipList = ipList.replace(/\[|]/g, '').replace(/["']/g, "").split(',')
            this.dataForm.virUidList = virUidList.replace(/\[|]/g, '').replace(/["']/g, "").split(',')


            if (this.dataForm.id !== 0) {
                this.dataForm.ipList.forEach(item => {
                    this.selectIpChange(item)
                })
            }
        },

        selectIpListChange(val) {
            let temp = Array.isArray(val) ? `${val.length === 0 ? 0 : val[val.length - 1]}` : val
            if (temp == 0) {
                this.dataForm.virUidList = []
                this.virUserOpt = []
            } else {
                this.virUserOpt.length = 0
                val.forEach(item => {
                    // this.virUserOpt.length = 0
                    if (item) {
                        this.selectIpChange(item)
                    }
                })
            }
        },

        selectIpChange(val) {
            if (val) {
                this.$http({
                    url: this.$http.adornUrl(`/admin/pushTaskNew/virIplist?ip=${val}`),
                    method: 'post',
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        if (data.result.length !== 0) {
                            this.virUserOpt = this.virUserOpt.concat(data.result[0])
                            let map = new Map();
                            for (let item of this.virUserOpt) {
                                if (!map.has(item.ip)) {
                                    map.set(item.ip, item)
                                }
                                item.virUserResDtoList = Array.from(new Set(item.virUserResDtoList.map(JSON.stringify)), JSON.parse)
                            }
                            this.virUserOpt = [...map.values()]

                            this.virUserOpt = this.virUserOpt.filter(function (item, index) {
                                return item['ip'] != 'all'
                            })
                            this.virUserOpt.push({
                                ip: 'all',
                                name: 'ALL',
                                virUserResDtoList: [{
                                    id: 'all',
                                    name: 'ALL'
                                }]
                            })
                            this.previousIpOptValue = JSON.parse(JSON.stringify(this.virUserOpt))
                        }
                    }
                })


            }
        },

        selectUserChange(val) {
            if (val.includes('all')) {
                this.dataForm.virUidList = val.filter(item => item == 'all')
            }
        },


        beforeSubmit() {
            if (this.dataForm.id) {
                this.$confirm('确认？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.dataFormSubmit();
                })
            } else {
                // 删除Ip后删除对应的角色
                let tempVirList = []
                this.previousIpOptValue.forEach(item => {
                    tempVirList = tempVirList.concat(item.virUserResDtoList)
                })
                tempVirList = tempVirList.map(item => {
                    return item.id;
                })

                for (let i = 0; i < this.dataForm.virUidList.length; i++) {
                    if (tempVirList.indexOf(this.dataForm.virUidList[i]) === -1) {
                        this.dataForm.virUidList.splice(i, 1);
                        i--;
                    }
                }
                this.dataFormSubmit();
            }
        },
        // 表单提交
        dataFormSubmit() {
            console.log('dataForm', this.dataForm)
            this.$http({
                url: this.$http.adornUrl(`/admin/chatMemoryTask/add`),
                method: 'post',
                data: this.$http.adornData({
                    ipList: JSON.stringify(this.dataForm.ipList),
                    lang: this.dataForm.lang,
                    content: this.dataForm.content,
                    memoryType: this.dataForm.memoryType,
                    virUidList: this.dataForm.virUidList.includes('all') ? this.dataForm.virUidList.toString() : JSON.stringify(this.dataForm.virUidList),
                })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.visible = false
                            this.$emit('refreshDataList')
                        }
                    })
                } else {
                    this.$message.error(data.msg)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
.input-width {
    width: 30vw;
}

.row {
    display: flex;
}

::v-deep .el-dialog__body {
    padding: 0 20px;
}

h3 {
    padding: 2vh 0;
    border-top: 1px solid rgba(184, 184, 184, 0.6)
}
</style>

<style>
/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
.el-dialog__wrapper::-webkit-scrollbar {
    display: none;
}

/* 隐藏 IE、Edge 和 Firefox 的滚动条 */
.el-dialog__wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.el-popup-parent--hidden {
    padding-right: 0 !important;
}
</style>